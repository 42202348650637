export enum EConfiguratorStep {
  PRODUCT_SELECTION = 'PRODUCT_SELECTION',
  SHAPE_TOP_SELECTION = 'SHAPE_TOP_SELECTION',
  DIMENSIONS = 'DIMENSIONS',
  SUMMARY = 'SUMMARY',
  CONTACT = 'CONTACT',
}

export enum EConfiguratorProductSliderMode {
  DISPLAY_ONLY = 'display',
  SLIDER = 'slider',
}

export enum EDimensionsType {
  TOTAL_LENGTH = 'TOTAL_LENGTH',
  INDIVIDUAL_ELEMENTS = 'INDIVIDUAL_ELEMENTS',
  SKETCH = 'SKETCH',
}

export enum EIndividualDimensionsTypes {
  THE_SAME_WIDTH = 'THE_SAME_WIDTH',
  DIFFERENT_WIDTH = 'DIFFERENT_WIDTH',
}

export enum ESummaryAdditionalProductsOptionsValue {
  YES_ADD_FENCE = 'YES_ADD_FENCE',
  YES_ADD_RAILING = 'YES_ADD_RAILING',
  YES_ADD_GARDEN_GATE = 'YES_ADD_GARDEN_GATE',
  YES_ADD_TWO_WINGS_GATE = 'YES_ADD_TWO_WINGS_GATE',
  YES_ADD_PRIVACY_GATE = 'YES_ADD_PRIVACY_GATE',
  YES_ADD_PRIVACY_GATE_DESIGN = 'YES_ADD_PRIVACY_GATE_DESIGN',
  YES_ADD_PRIVACY_SCREEN = 'YES_ADD_PRIVACY_SCREEN',
  YES_ADD_PRIVACY_SCREEN_DESIGN = 'YES_ADD_PRIVACY_SCREEN_DESIGN',
  YES_ADD_PRIVACY_DOOR = 'YES_ADD_PRIVACY_DOOR',
  NO_DONT_NEED = 'NO_DONT_NEED',
  CHECK_SKETCH = 'CHECK_SKETCH',
}

export enum ETopsIds {
  KREUZLILIE = 'kreuzlilie',
  KUGELLILIE = 'kugellilie',
  KUGELSTAB = 'kugelstab',
  LANZE = 'lanze',
  LILIE = 'lilie',
  LOTUS = 'lotus',
  PINIE = 'pinie',
  STAB = 'stab',
  AUSGEHAMMERTE = 'ausgehammerte',
}

export enum EShapesIds {
  GERADE = 'gerade',
  GEBOGEN = 'gebogen',
  GESCHWUNGEN = 'geschwungen',
  U_FORM = 'u-form',
}

export enum EPostOption {
  FOR_CONCRETING = 'FOR_CONCRETING',
  UNSCREW = 'UNSCREW',
  WITH_SPECIAL_ATTACHMENT = 'WITH_SPECIAL_ATTACHMENT',
  FRONT_MOUNTING = 'FRONT_MOUNTING',
  FRONT_MOUNTING_WITH_OVERHANG = 'FRONT_MOUNTING_WITH_OVERHANG',
  FASTENING_TO_THE_WALL = 'FASTENING_TO_THE_WALL',
  NO = 'NO',
}

export enum EContactSalutationOption {
  MR = 'MR',
  MS = 'MS',
  COMPANY = 'COMPANY',
}

export enum EConfiguratorResultStatus {
  COMPLETE = 'COMPLETE',
  CLOSE = 'CLOSE',
}

export enum ECoatingOptions {
  UNBESCHICHTET = 'unbeschichtet',
  FEURVERZINKT = 'feuerverzinkt',
  ANTHRAZIT = 'anthrazit',
  SONDERFARBE = 'sonderfarbe',
}

export enum EConfiguratorSections {
  Shapes = 'Shapes',
  Tops = 'Tops',
  Coatings = 'Coatings',
  GroundPlans = 'GroundPlans',
}

export enum EGroundPlans {
  GERADE = 'GERADE',
  L_FORM = 'L_FORM',
  U_FORM = 'U_FORM',
  SONDERFORM = 'SONDERFORM',
}

export enum EPostType {
  METALKAPPE = 'METALKAPPE',
  ZWIRBEL = 'ZWIRBEL',
}

export enum EHandrailsIds {
  RUND = 'rund',
  TRAVERSE = 'Traversenabschluss',
  HALBRUND = 'halbrund',
}

export enum EMaterialIds {
  UNBESCHICHTET = 'unbeschichtet',
  METALL = 'metall',
  ANTHRAZIT = 'anthrazit',
  SONDERFARBE = 'sonderfarbe',
}

export enum EBackWallIds {
  OHNE = 'ohne',
  BLANK = 'blank',
  DESIGN = 'design',
}

export enum EImageResolutionType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}
