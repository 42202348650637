export enum EValidators {
  REQUIRED = 'REQUIRED',
  REQUIRED_RADIO = 'REQUIRED_RADIO',
  REQUIRED_FILES = 'REQUIRED_FILES',
  MAX = 'MAX',
  MIN = 'MIN',
  EMAIL = 'EMAIL',
  NAGATIVE_NUMBER = 'NAGATIVE_NUMBER',
  NOT_NUMBER = 'NOT_NUMBER',
  NOT_LETTER = 'NOT_LETTER',
  NOT_SPECIAL_CHARACTER = 'NOT_SPECIAL_CHARACTER',
  NOT_ARROW_CHARACTER = 'NOT_ARROW_CHARACTER',
  MIN_LENGTH = 'MIN_LENGTH',
  MAX_LENGTH = 'MAX_LENGTH',
  PHONE = 'PHONE',
}
