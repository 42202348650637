import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface IProtectedRouteProps extends RouteProps {
  isLoggedIn: boolean;
  redirectPath: string;
}

const ProtectedRoute: FC<IProtectedRouteProps> = ({ isLoggedIn, redirectPath, ...props }) => {
  if (!isLoggedIn) {
    return <Redirect to={{ pathname: redirectPath }} />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
