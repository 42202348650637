import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { LoaderSuspense } from 'components/loader/loader-suspense';
import ProtectedRoute from 'components/routes/protected-route';
import PublicRoute from 'components/routes/public-route';
import { AuthContext } from 'utils/auth/auth-context';

const Configurator = lazy(() => import('./configurator/configurator'));
const ImagesReport = lazy(() => import('./images-report/images-report'));
const LogIn = lazy(() => import('./log-in/log-in'));

export const PageRouter = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <BrowserRouter basename={'/obi'}>
      <Suspense fallback={<LoaderSuspense />}>
        <Switch>
          <PublicRoute
            path={'/log-in'}
            key={'LogIn'}
            redirectPath={'/'}
            isLoggedIn={isLoggedIn}
            render={() => <LogIn />}
            restricted
            exact
          />

          <ProtectedRoute
            path={'/images-report'}
            key={'ImagesReport'}
            redirectPath={'/log-in'}
            isLoggedIn={isLoggedIn}
            render={(routeProps) => <ImagesReport {...props} {...routeProps} />}
          />

          <ProtectedRoute
            path={'/'}
            key={'Configurator'}
            redirectPath={'/log-in'}
            isLoggedIn={isLoggedIn}
            render={(routeProps) => <Configurator {...props} {...routeProps} />}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
