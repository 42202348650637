export enum EInputType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  NUMBER = 'number',
  PHONE = 'phone',
  RADIO = 'radio',
}

export enum EInputKey {
  SPECIAL_COLOR = 'SPECIAL_COLOR',
  HEIGHT = 'HEIGHT',
  HEIGHT_MIDDLE = 'HEIGHT_MIDDLE',
  WIDTH = 'WIDTH',
  QUANTITY = 'QUANTITY',
  TOTAL_LENGTH = 'TOTAL_LENGTH',
}
