export enum EProductCategoryKey {
  FENCE = 'zaun',
  GATE = 'tor',
  GARDEN_GATE = 'gartentor',
  RAILING = 'gelander',
  // PRIVACY_SCREEN = 'sichtschutz',
  PRIVACY_SCREEN = 'privacy_screen',
  RAILING_WITH_PRIVACY_SCREEN = 'railing_with_privacy_screen',
  PRIVACY_GATE = 'PRIVACY_GATE',
  PRIVACY_DOOR = 'PRIVACY_DOOR',
}

export enum EProductSubCategoryKey {
  PRIVACY_SCREEN = 'privacy_screen',
  RAILING_WITH_PRIVACY_SCREEN = 'railing_with_privacy_screen',
}

export enum EProductMainCategoryKey {
  FENCES_GATES_RAILINGS = 'fences_gates_railings',
  PRIVACY_SCREENS = 'privacy_screens',
}
