import React, { createContext, ReactNode, useEffect, useState } from 'react';

export const AuthContext = createContext(null);

interface IAuthProviderProps {
  children: ReactNode;
}

interface IAuthObject {
  token: string;
  expirationDate: number;
}

const TOKEN_EXPIRATION_TIME = 900;

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const authTokenData = localStorage.getItem('auth_token_data');

    if (!authTokenData) {
      return;
    }

    const authObject: IAuthObject = JSON.parse(authTokenData);
    const currentDate = Math.round(new Date().getTime() / 1000);

    if (authObject.expirationDate > currentDate) {
      setToken(authObject.token);
      setIsLoggedIn(true);
    } else {
      logOut();
    }
  }, []);

  const logIn = (newToken: string) => {
    const newExpirationDate = Math.round(new Date().getTime() / 1000) + TOKEN_EXPIRATION_TIME;
    const newAuthObject: IAuthObject = { token: newToken, expirationDate: newExpirationDate };
    localStorage.setItem('auth_token_data', JSON.stringify(newAuthObject));

    setToken(newToken);
    setIsLoggedIn(true);
  };

  const logOut = () => {
    localStorage.removeItem('auth_token_data');

    setToken(null);
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        isLoggedIn,
        logIn,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
