import React from 'react';

import { PageRouter } from 'pages';
import { AuthProvider } from 'utils/auth/auth-context';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <PageRouter />
    </AuthProvider>
  );
};

export default App;
