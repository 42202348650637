export enum EModalContent {
  UNCOATED = 'UNCOATED',
  GALVANIZED = 'GALVANIZED',
  GATE_IMAGE_PREVIEW = 'GATE_IMAGE_PREVIEW',
  ANTHRACITE_POWDER_COATED = 'ANTHRACITE_POWDER_COATED',
  POWDER_COATED_SPECIAL_COLOR = 'POWDER_COATED_SPECIAL_COLOR',
  OVERALL_LENGTH = 'OVERALL_LENGTH',
  INDIVIDUAL_ELEMENTS = 'INDIVIDUAL_ELEMENTS',
  SKETCH = 'SKETCH',
  POST_FOR_CONCRETING = 'POST_FOR_CONCRETING',
  POST_FOR_CONCRETING_RAILING = 'POST_FOR_CONCRETING_RAILING',
  POST_FOR_FRONT_MOUNTING = 'POST_FOR_FRONT_MOUNTING',
  POST_FOR_FASTENING_TO_THE_WALL = 'POST_FOR_FASTENING_TO_THE_WALL',
  POST_FOR_FRONT_MOUNTING_WITH_OVERHANG = 'POST_FOR_FRONT_MOUNTING_WITH_OVERHANG',
  SCREW_ON_POST = 'SCREW_ON_POST',
  POSTS_ARE_AVAILABLE = 'POSTS_ARE_AVAILABLE',
  SCREW_ON_RAILING_POST = 'SCREW_ON_RAILING_POST',
  POST_WITH_SPECIAL_FASTENING = 'POST WITH SPECIAL FASTENING',
  WHAT_IS_HOHE_SEITE = 'WHAT IS HOHE SEITE',
  NO_BACK_WALL = 'NO_BACK_WALL',
  BACK_WALL_WITHOUT_DESIGN = 'BACK_WALL_WITHOUT_DESIGN',
  BACK_WALL_WITH_DESIGN = 'BACK_WALL_WITH_DESIGN',
}

export enum EModalStatus {
  YES = 'YES',
  NO = 'NO',
  CANCEL = 'CANCEL',
}

export enum EModalType {
  SMALL = 'SMALL',
  FULL_SCREEN = 'FULL_SCREEN',
}
