import React from 'react';
import { Routes } from 'shared/consts';
import { Route, RouteProps, Redirect } from 'react-router-dom';

interface IPublicRouteProps extends RouteProps {
  isLoggedIn?: boolean;
  restricted?: boolean;
  redirectPath?: string;
}

const PublicRoute: React.FC<IPublicRouteProps> = ({
  isLoggedIn = false,
  restricted = false,
  redirectPath = Routes.KONFIGURATOR,
  ...props
}) => {
  if (isLoggedIn && restricted) {
    return <Redirect to={{ pathname: redirectPath }} />;
  }

  return <Route {...props} />;
};

export default PublicRoute;
