import styled, { keyframes } from 'styled-components';

import { COLOR, rem } from 'utils/styles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
`;

export const Loader = styled.div`
  display: inline-block;
  width: ${rem(80)};
  height: ${rem(80)};
  &:after {
    content: ' ';
    display: block;
    width: ${rem(64)};
    height: ${rem(64)};
    margin: ${rem(8)};
    border-radius: 50%;
    border: ${rem(6)} solid ${COLOR.GREEN_400};
    border-color: ${COLOR.GREEN_400} transparent ${COLOR.GREEN_400} transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;
