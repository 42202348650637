import React from 'react';

import { Container, Loader } from './styles';

export const LoaderSuspense = () => {
  return (
    <Container>
      <Loader></Loader>
    </Container>
  );
};
