import { ERoutesName } from "shared/enums";

const common = '/obi';

export const Routes = {
  [ERoutesName.KONFIGURATOR]: `${common}`,
  [ERoutesName.SHAPE_TOP_SELECTION]: `${common}/shape_top_selection`,
  [ERoutesName.DIMENSIONS]: `${common}/dimensions`,
  [ERoutesName.SUMMARY]: `${common}/summary`,
  [ERoutesName.CONTACT]: `${common}/contact`
}
