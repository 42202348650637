export const environment = {
  api: {
    url: 'https://www.eleo-zaun.de/rest/V1/',
    // url: 'https://test.eleo-zaun.de/rest/V1/',
    // url: 'https://eleo-fe-dev.herokuapp.com/data/',
    // url: 'http://eleo.devsintra.pl/rest/V1/',
    // url: 'http://localhost:3000/data/',
  },
  // productsCategoryName: 'Produkte',
  productsCategoryName: 'Configurator New',
  imagesUrl: 'https://www.eleo-zaun.de/pub/media/configurator/',
  // imagesUrl: 'https://test.eleo-zaun.de/pub/media/configurator/',
  // imagesUrl: 'http://eleo:eleo1234!@test.eleo-zaun.de/media/import/',
  // imagesUrl: 'https://eleo-fe-dev.herokuapp.com/images/products/',
  defaultDomainExtension: 'de',
  notAllowedDomainExtensions: ['at', 'ch'],
};
