export const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const SIMPLE_PHONE_REGEX = /\d{4,}/i;

export const EXTENDED_PHONE_REGEX = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/i;

export const LETTER_REGEX = /[A-z]/i;

export const NUMBER_REGEX = /[0-9]/i;

export const SPECIAL_CHARACTER_REGEX = /[^A-z0-9 ]/i;

export const ARROW_CHARACTER_REGEX = /^[^<>]*$/i;
