export const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#000000',

  GRAY_100: '#f7f6f4',
  GRAY_200: '#d7d7d6',
  GRAY_250: '#eeebe5',
  GRAY_300: '#c6bdaa',
  GRAY_350: '#b3b2b1',
  GRAY_400: '#888888',
  GRAY_600: '#4c4c4c',
  GRAY_700: '#3d3a36',
  GRAY_800: '#333333',
  GRAY_900: '#1f1c17',

  GREEN_100: '#f1f4ed',
  GREEN_150: '#e1e9d9',
  GREEN_175: '#d6e1ca',
  GREEN_185: '#d5dbce',
  GREEN_200: '#9eb781',
  GREEN_300: '#90ae6e',
  GREEN_400: '#8ca371',
  GREEN_500: '#7a8e62',
  GREEN_600: '#566e3a',

  RED_500: '#e54b4b',
};

export const BREAKPOINT = {
  XXS: 480,
  XS: 576,
  SM: 767,
  MD: 992,
  LG: 1240,
};

export const FONT_WEIGHT = {
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  BLACK: 900,
};

export const rem = (value: number | number[], baseValue = 16) => {
  if (Array.isArray(value)) {
    let result = '';
    for (const elem of value) {
      result += elem / baseValue + 'rem ';
    }
    return result;
  }
  return value / baseValue + 'rem';
};
