import { environment } from 'environment';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const redirectToDefaultDomainExtension = () => {
  const { hostname, pathname, protocol } = window.location;
  const { defaultDomainExtension, notAllowedDomainExtensions } = environment;
  const chunks = hostname.split('.');

  if (notAllowedDomainExtensions.includes(chunks[chunks.length - 1])) {
    chunks[chunks.length - 1] = defaultDomainExtension;

    const redirectUrl = `${protocol}//${chunks.join('.')}${pathname}`;
    window.location.replace(redirectUrl);
  }
};
